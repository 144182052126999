<template>
  <div class="popup_wrap" style="width:800px;"><!-- 팝업사이즈 참고 : 800*600  popup_wrap -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('closeAlarmPop')"
    >close</button>
    <form id="frm_bldo_alarm">
      <div class="popup_cont" ><!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.ONIM050G020.001') }}</h1><!-- 선적지 B/L 발급 문의 및 알림 -->

        <div class="content_box"><!-- content_box -->
          <template v-if="params.status === 'END'">
            <p>
              {{ $t('msg.ONIM050G020.002') }}<br><br><!-- 이미 B/L 발행, 접수등의 처리가 완료되었습니다. -->
              {{ $t('msg.ONIM050G020.003') }}<!-- 자동 알람 서비스는 미발행이거나 미접수 건에 대해서만 신청이 가능합니다. -->
            </p>
          </template>
          <template v-if="params.status !== 'END'">
            <p>
              {{ $t('msg.ONIM050G020.004') }}<br><!-- 해당 요청 건의 B/L 발급 결과를 원하시는 매체를 선택하여 받아보실 수 있습니다. -->
              {{ $t('msg.ONIM050G020.005') }}<!-- 해당 건에 대한 자동 알림 서비스를 받으시겠습니까? -->
            </p>
            <div class="line_box">
              <P class="mt10 text_center color_blue2 font_medium">
                <input
                  v-model="params.userInfo.emlTrmsYnBak"
                  type="checkbox"
                  id="email"
                  name="notice"
                ><label for="email" class="mr50"><span></span>{{ $t('msg.ONIM050G020.006') }}</label><!-- Email -->
                <input
                  v-model="params.userInfo.smsSntYnBak"
                  type="checkbox"
                  id="sms"
                  name="notice"
                >
                <label for="sms"><span></span>{{ $t('msg.ONIM050G020.007') }}</label><!-- SMS -->
              </P>
            </div>
            <table class="tbl_row mt15">
              <colgroup>
                <col width="120">
                <col>
                <col width="120">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('msg.ONIM050G020.008') }}</th><!-- 성명 -->
                  <td>
                      <input
                        v-model="params.userInfo.userName"
                        type="text"
                        class="wid200"
                        id="userName"
                        @keyup="fnValidationInput('userName')"
                      >
                    </td>
                  <th>{{ $t('msg.ONIM050G020.009') }}</th><!-- 상호 -->
                  <td>
                    <input
                      v-model="params.userInfo.userCompName"
                      type="text"
                      class="wid200"
                      id="userCompName"
                      @keyup="fnValidationInput('userCompName')"
                    >
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.ONIM050G020.010') }}</th><!-- email -->
                  <td>
                    <input
                      v-model="params.userInfo.userEmail"
                      type="text"
                      class="wid200"
                      id="userEmail"
                    >
                  </td>
                  <th>{{ $t('msg.ONIM050G020.011') }}</th><!-- 휴대폰 -->
                  <td>
                    <div id="userCel">
                      <e-input-number
                        v-model="params.userInfo.userCel1"
                        :digitMax="3"
                        :isPhone=true
                        class="wid50"
                      />
                      <e-input-number
                        v-model="params.userInfo.userCel2"
                        :digitMax="12"
                        :isPhone=true
                        class="wid150"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div> <!-- content_box // -->

        <div class="mt10 text_center">
          <a
            v-if="params.status !== 'END'"
            class="button blue lg"
            href="#"
            @click.prevent="insertAlarm()"
          >{{ $t('msg.ONIM050G020.012') }}</a><!-- 확인 -->
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('closeAlarmPop')"
          >{{ $t('msg.ONIM050G020.013') }}</a><!-- 닫기 -->
        </div>

      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import blDoPayment from '@/api/rest/trans/blDoPayment'

export default {
  name: 'BlDoAlarmPop',
  components: {
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          blDoPayment: undefined
        }
      }
    }
  },
  data: function () {
    return {
      params: {
        blNo: this.parentInfo.blNo,
        status: 'INS',
        emailSendYn: 'N',
        userInfo: {
          reqRnoBak: '',
          smsSntYnBak: false,
          emlTrmsYnBak: false,
          userName: '',
          userCompName: '',
          userEmail: '',
          userCel1: '',
          userCel2: ''
        }
      },
      blDoPayment: this.parentInfo.blDoPayment
    }
  },
  created () {},
  mounted () {
    if (this.params.blNo !== undefined) {
      this.getAlarmInfo()
    }
  },
  methods: {
    async getAlarmInfo () {
      let params = {
        blNo: this.params.blNo
      }

      if (this.blDoPayment !== undefined) {
        params = {
          ...params,
          ...{
            surrEmailyn: this.blDoPayment.surremailyn,
            vslNm: this.blDoPayment.vslNm,
            voyNo: this.blDoPayment.voyNo,
            podPortCd: this.blDoPayment.podPortCd,
            doStsCd: this.blDoPayment.doStsCd
          }
        }
      }

      await blDoPayment.popAlarmForm(params)
        .then(async (response) => {
          const resp = response.data
          const TH = this

          if (resp !== undefined) {
            if (resp.processSts === 'FAIL') {
              // 선적지 B/L 발급 문의에 실패했습니다. 관리자에 문의 바랍니다.
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM050G020.014') })) {
                TH.$emit('close')
              }
            } else {
              // test
              // this.params.status = 'INS'
              // this.params.status = 'UPD'
              this.params.status = resp.status
              this.params.emailSendYn = resp.emailSendYn
              if (resp.userInfo !== undefined) {
                this.params.userInfo = {
                  ...this.params.userInfo,
                  ...{
                    userName: resp.userInfo.userName,
                    userCompName: resp.userInfo.userCompName,
                    userEmail: resp.userInfo.userEmail,
                    userCel1: resp.userInfo.userCel1,
                    userCel2: resp.userInfo.userCel2
                  }
                }

                if (resp.userInfo.emlTrmsYnBak === 'Y') this.params.userInfo.emlTrmsYnBak = !this.params.userInfo.emlTrmsYnBak
                if (resp.userInfo.smsSntYnBak === 'Y') this.params.userInfo.smsSntYnBak = !this.params.userInfo.smsSntYnBak
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    fnValidationCheckBox () {
      const smsSntYn = this.params.userInfo.smsSntYnBak
      const emlTrmsYn = this.params.userInfo.emlTrmsYnBak

      let isOk = true

      if (!smsSntYn && !emlTrmsYn) {
        // 자동 알림 서비스를 요청시에는 E-MAIL 이나 SMS 를 하나 이상 선택하여 주십시요.
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM050G020.015'))
        isOk = false
      }

      return isOk
    },
    fnValidationInput (flag, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_bldo_alarm')
      }

      let isOk = true
      let elem = ''
      let msg = ''
      // let value = ''

      elem = frm.querySelector(`#${flag}`)

      // switch (flag) {
      //   case 'userName':
      //     value = this.params.userName
      //     msg = '성명을 입력해 주세요.'
      //     break
      //   case 'userCompName':
      //     value = this.params.userCompName
      //     msg = '상호를 입력해 주세요.'
      //     break
      // }

      if (flag === 'userName') {
        if (this.$ekmtcCommon.isEmpty(this.params.userInfo.userName)) {
          isOk = false
          msg = this.$t('msg.ONIM050G020.016') // 성명을 입력해 주세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      }

      if (flag === 'userCompName') {
        if (this.$ekmtcCommon.isEmpty(this.params.userInfo.userCompName)) {
          isOk = false
          msg = this.$t('msg.ONIM050G020.017') // 상호명을 입력해 주세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      }

      if (flag === 'userEmail') {
        if (this.$ekmtcCommon.isEmpty(this.params.userInfo.userEmail)) {
          isOk = false
          msg = this.$t('msg.ONIM050G020.018') // email를 입력해 주세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      }

      if (flag === 'userCel') {
        if (this.$ekmtcCommon.isEmpty(this.params.userInfo.userCel1)) {
          isOk = false
          msg = this.$t('msg.ONIM050G020.019') // 휴대폰 앞자리 번호를 입력해 주세요.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)

          if (this.$ekmtcCommon.isEmpty(this.params.userInfo.userCel2)) {
            isOk = false
            msg = this.$t('msg.ONIM050G020.020') // 휴대폰 뒷자리 번호를 입력해 주세요.
            this.$ekmtcCommon.showErrorTooltip(elem, msg)
          } else {
            this.$ekmtcCommon.hideErrorTooltip(elem)
          }
        }
      }

      return isOk
    },
    fnValidationAll () {
      const frm = document.querySelector('#frm_bldo_alarm')

      const smsSntYn = this.params.userInfo.smsSntYnBak
      const emlTrmsYn = this.params.userInfo.emlTrmsYnBak

      let isAllOk = true

      if (!this.fnValidationCheckBox()) {
        // isAllOk = false
        return false
      }

      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#userEmail'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#userCel'))

      if (!this.fnValidationInput('userName', frm)) {
        isAllOk = false
      }
      if (!this.fnValidationInput('userCompName', frm)) {
        isAllOk = false
      }
      if (emlTrmsYn && !this.fnValidationInput('userEmail', frm)) {
        isAllOk = false
      }
      if (smsSntYn && !this.fnValidationInput('userCel', frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    async insertAlarm () {
      if (this.fnValidationAll()) {
        const params = {
          blNo: this.params.blNo,
          status: this.params.status,
          reqRno: this.params.userInfo.reqRnoBak,
          emlTrmsYn: this.params.userInfo.emlTrmsYnBak ? 'Y' : 'N',
          smsSntYn: this.params.userInfo.smsSntYnBak ? 'Y' : 'N',
          reqUsrNm: this.params.userInfo.userName,
          reqPicNm: this.params.userInfo.userCompName,
          emlAddr: this.params.userInfo.userEmail,
          mbpBsnNo: this.params.userInfo.userCel1,
          mbpOfcNo: this.params.userInfo.userCel2
        }

        await blDoPayment.popAlarmSav(params)
          .then(async (response) => {
            const TH = this
            let msg = ''

            if (response.headers.respcode === 'C0000') {
              msg = this.$t('msg.ONIM050G020.021') // 자동 알람 서비스 요청이 완료되었습니다!
            } else if (response.headers.respcode === 'T0020') {
              msg = this.$t('msg.ONIM050G020.022') // 자동 알람 서비스 수정이 완료되었습니다!
            } else {
              msg = this.$t('msg.ONIM050G020.023') // 저장중 에러가 발생되어 자동 알람 서비스 요청이 취소되었습니다.계속 에러가 발생된다면 관리자에게 문의하여 주세요.
            }

            if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg })) {
                TH.$emit('closeAlarmPop', { surremailyn: this.params.emailSendYn })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
}
</script>
